import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "./app/components/Loading";
import './App.scss';

const UI = lazy(() => import('./app/routes/UI'));
const RF = lazy(() => import('./app/routes/RF'));

const App = (props) => {

	return (
		<Router>
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route path="/rf" {...props} component={RF}></Route>
					<Route path="/" {...props} component={UI}></Route>
				</Switch>
			</Suspense>
		</Router>
	);
};

export default App;
