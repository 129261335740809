import "./index.scss";

const Loading = () => {
	return (
		<div className="Loading">
			<div className="loadingio-spinner-spinner-vpufwojq17o">
				<div className="ldio-mkp0q1l4nc">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default Loading;
