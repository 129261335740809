// warehousesSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const warehousesSlice = createSlice({
    name: "warehouses",
    initialState: [],
    reducers: {
        setWarehouses: (state, action) => {
            return action.payload;
        },
    },
});

export const { setWarehouses } = warehousesSlice.actions;

export default warehousesSlice.reducer;